.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 50%;
  transform: translateY(85%);
  margin-left: auto;
  position: absolute;
  right: 2%;

  .cubespinner {
    animation: spincube 12s ease-in-out infinite;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
    display: block; /* Ensure spinner is visible by default */

    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
    }

    .face1 { transform: translateZ(100px); color: #dd0031; }
    .face2 { transform: rotateY(90deg) translateZ(100px); color: #f06529; }
    .face3 { transform: rotateY(90deg) rotateX(90deg) translateZ(100px); color: #28a4d9; }
    .face4 { transform: rotateY(180deg) rotateZ(90deg) translateZ(100px); color: #5ed4f4; }
    .face5 { transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px); color: #efd81d; }
    .face6 { transform: rotateX(-90deg) translateZ(100px); color: #ec4d28; }
  }
}

@keyframes spincube {
  from, to { transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
  16% { transform: rotateY(-90deg) rotateZ(90deg); }
  33% { transform: rotateY(-90deg) rotateX(90deg); }
  50% { transform: rotateY(-180deg) rotateZ(90deg); }
  66% { transform: rotateY(-270deg) rotateX(90deg); }
  83% { transform: rotateX(90deg); }
}

.custom {
  border: 2px solid #343a40;
  margin: 10px;
  border-radius: 15px;
  padding: 15px;
  background: linear-gradient(to right, #495057, #343a40);
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  text-align: center;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

/* Media Query for devices smaller than 768px (typically mobile devices) */
@media (max-width: 767px) {
  .stage-cube-cont .cubespinner {
    display: none; /* Hide spinner on smaller devices */
  }
}
