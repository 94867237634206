@media (max-width: 768px) {
    .text {
      display: none; /* Hide text on smaller screens */
    }

    .icon-wrapper {
        width: 40px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      
  }