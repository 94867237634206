body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

.container-fluid {
  padding: 0 15px;
}

.navbar-brand {
  font-size: 2rem;
  font-family: 'Arial', sans-serif;
}

.navbar-nav .nav-link {
  font-size: 1.2rem;
}

.card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.btn-secondary:hover {
  background-color: #5a6268;
  transform: scale(1.05);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-up {
  animation: fadeInUp 0.6s ease-in-out;
}

.fade-in {
  animation: fadeIn 0.6s ease-in-out;
}

@keyframes parallax {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

.parallax {
  animation: parallax 20s linear infinite;
}

@media (max-width: 768px) {
  .navbar-brand {
    font-size: 1.5rem;
  }

  .navbar-nav .nav-link {
    font-size: 1rem;
  }

  .card {
    margin-bottom: 20px;
  }
}
